@layer components {
  .ishetb1-form {
    @apply grid grid-cols-2;
    @apply lg:grid-cols-3;

    .form-value {
      @apply lg:col-span-2;
    }
  }
}
