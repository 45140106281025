@layer components {
  .lve-button, .lve-search-button {
    @apply text-white;
    @apply bg-lve-pink;
    @apply py-2 px-5;
    @apply hover:no-underline;
  }

  .lve-button {
    @apply rounded-full;
  }

  .lve-text-input, .lve-search-input {
    @apply text-black;
    @apply bg-white;
    @apply placeholder-lve-black;
    @apply py-2;
    @apply px-4;
  }

  .lve-text-input {
    @apply rounded-full;
  }

  .lve-search-input {
    @apply rounded-l-full;
    @apply mr-0;

    max-width: 16rem;
    @apply w-32;
    @apply flex-grow;
  }

  .lve-search-button {
    @apply rounded-r-full;
    @apply ml-1;
  }

  .lve-text-input::placeholder, .lve-search-input::placeholder {
    @apply text-sm italic;
  }

  .lve-radio-input, .lve-checkbox-input {
    appearance: none;

    @apply w-5;
    @apply h-5;
    @apply rounded-full;
    @apply align-top;
    @apply border-white;
    @apply border-2;

    @apply bg-white;
  }

  .lve-radio-input:checked, .lve-checkbox-input:checked {
    @apply bg-lve-pink;
  }
}
