@layer components {
  .ishetb1-introduction-block {
    @apply lg:col-span-2;
    @apply rounded-t-3xl;
    @apply lg:rounded-l-3xl;
    @apply lg:rounded-r-none;
  }

  .ishetb1-verdict, .ishetb1-flash-notice, .ishetb1-popular-words-block > h2 {
    @apply lg:rounded-tr-3xl;
  }

  .ishetb1-about-lve-block, .ishetb1-alternatives-block {
    @apply lg:col-start-3;
    min-height: 14rem;
  }

  .ishetb1-about-lve-block, .ishetb1-alternatives-block > ul {
    @apply rounded-b-3xl;
  }

  .ishetb1-footer {
    @apply lg:row-start-2;
    @apply lg:col-span-2;
  }

  .ishetb1-footer-faq {
    @apply lg:col-start-3;
  }

  .ishetb1-flash-notice {

  }

  .ishetb1-suggestion-form {
    @apply lg:col-span-2 lg:rounded-bl-3xl;
  }

  .ishetb1-with-suggestion-form {
    .ishetb1-introduction-block, .ishetb1-about-lve-block, .ishetb1-alternatives-block > ul {
      @apply lg:rounded-bl-none;
    }
    .ishetb1-footer {
      @apply lg:row-start-3;
    }
  }
}
