@layer components {
  .ishetb1-faq-block {
    @apply border-2 border-lve-blue rounded-3xl p-4;
    @apply my-1;

    h2 {
      @apply text-2xl text-lve-blue;
    }

    h3 {
      @apply font-bold mt-6;
    }

    p {
      @apply mb-1;
    }

    ol > li {
      @apply mt-6;
    }

    li > h3 {
      @apply inline;
    }
  }
}
