@layer base {
  html {
    font-size: 133%;
  }

  a:hover {
    @apply underline;
  }

  body {
    @apply font-sans;
    @apply leading-normal;
    @apply text-lve-black;
  }
}
